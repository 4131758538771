import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
// import logger from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'


import rootReducer from './rootReducer'

const configureStore = (preloadedState) => {
  const middlewares = [thunk]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers)

  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  return store
}


export default configureStore