import React from "react";
import { useSelector } from "react-redux";
import { getMemoServices } from "../../redux/services/services.selector";

const Services = () => {

    const services = useSelector(getMemoServices)


    return (

        services &&
        (<div className="rn-service-area rn-section-gap bg_color--5" id="service">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div
                            className="section-title service-style--3 text-center mb--20 mb_sm--0 md_md--0">
                            <h2 className="title">{services.attributes.title}</h2>
                            <p>{services.attributes.description}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        services.attributes.card && services.attributes.card.map((card) => {
                            return (
                                <div key={card.id} className="col-lg-4 col-md-6 col-sm-6 col-12 mt--40">
                                    <div className="single-service service__style--2 bg-color-gray">
                                        <a>
                                            <div className="service">                                                
                                                <div className="content">
                                                    <h3 className="title">{ card.card_title }</h3>
                                                    <p>{ card.card_description }</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                    
                </div>
            </div>
        </div>)

    )
}

export default Services;