import React from "react";


const Contact = () => {
    return (
        <div className="rn-contact-area rn-section-gap bg_color--1" id="contact" >
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-center">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div
                                className="section-title text-start mb--50 mb_sm--30 mb_md--30">
                                <h2 className="title">Get in touch.</h2>
                                <p className="description">
                                    Connect with me via
                                    phone:&nbsp;
                                    <a href="tel:+917709790007">+91 77097 90007</a> or
                                    email:
                                    <a href="mailto:connect@grvroy.com">
                                    connect@grvroy.com</a>
                                </p>
                            </div>
                            {/* <div className="form-wrapper">
                                <form>
                                    <label htmlFor="item01">
                                        <input type="text" name="name" id="item01"
                                            placeholder="Your Name *" />
                                    </label>

                                    <label htmlFor="item02">
                                        <input type="text" name="email" id="item02"
                                            placeholder="Your email *" />
                                    </label>

                                    <label htmlFor="item03">
                                        <input type="text" name="subject"
                                            id="item03"
                                            placeholder="Write a Subject" />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea type="text" id="item04"
                                            name="message"
                                            placeholder="Your Message"></textarea>
                                    </label>
                                    <button className="rn-button-style--2 btn_solid"
                                        type="submit" value="submit" name="submit"
                                        id="mc-embedded-subscribe">Submit</button>
                                </form>
                            </div> */}
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/about/about-9.jpg" alt="trydo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;