import axios from "axios"
import { FETCH_CATEGORIES_FAILURE, FETCH_CATEGORIES_REQUEST, FETCH_CATEGORIES_SUCCESS } from "./categories.types"

export const fetchCategoriesRequest = () => {
    return {
        type: FETCH_CATEGORIES_REQUEST
    }
}


export const fetchCategoriesSuccess = (categories) => {
    return {
        type: FETCH_CATEGORIES_SUCCESS,
        payload: categories
    }
}


export const fetchCategoriesFailure = (error) => {
    return {
        type: FETCH_CATEGORIES_FAILURE,
        payload: error 
    }
}



export const fetchCategories = () => {
    return (dispatch) => {
        dispatch(fetchCategoriesRequest)

        axios.get(`${process.env.REACT_APP_API_URL}/categories?populate=*`, {
            headers: {
                'Authorization': `bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        })
            .then((reponse) => {
                const categories = reponse.data
                dispatch(fetchCategoriesSuccess(categories))

            })
            .catch((error) => {
                const errMsg = error.message
                dispatch(fetchCategoriesFailure(errMsg))
            })
    }
}