import React from "react";
import { Link } from "react-router-dom";


const ProjectCard = (props) => {
    const { Title, category, Thumbnail, Slug } = props.project.attributes;


    return (
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt--30">

            <div className="portfolio text-center">
                <div className="thumbnail-inner">
                    <div className="thumbnail image-8" style={{ backgroundImage: "url(" + process.env.REACT_APP_DATA_URL + Thumbnail.data.attributes.url + ")" }}></div>
                    <div className="bg-blr-image image-8" style={{ backgroundImage: "url(" + process.env.REACT_APP_DATA_URL + Thumbnail.data.attributes.url + ")" }}></div>
                </div>
                <div className="content">
                    <div className="inner">
                        <p>{category && category.data.attributes.Category}</p>
                        <h4><Link to={{ pathname: `/portfolio/${Slug}` }}>{Title}</Link>
                        </h4>
                        <div className="portfolio-button">
                            <Link className="rn-btn" to={{ pathname: `/portfolio/${Slug}` }}>View</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectCard;