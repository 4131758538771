import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer-style-2 ptb--30 bg_image bg_image--5" data-black-overlay="6" >
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner">
                            <div className="logo text-center text-sm-start mb_sm--20">
                                <Link to="/">
                                    <img src={process.env.REACT_APP_PUBLIC_URL + '/assets/images/logo_white.svg'} className="logo-img" alt="Gourav Roy" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner text-center">
                            <ul
                                className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                <li><a target="_blank" href="https://www.facebook.com/grvroy9"><i className="fab fa-facebook-f"></i></a></li>
								<li><a target="_blank" href="https://www.linkedin.com/in/grvroy90/"><i className="fab fa-linkedin-in"></i></a></li>
								<li><a target="_blank" href="https://twitter.com/grvroy90"><i className="fab fa-twitter"></i></a></li>
								<li><a target="_blank" href="https://www.instagram.com/gourav_r_official/"><i className="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className="inner text-lg-end text-center mt_md--20 mt_sm--20">
                            <div className="text">
                                <p>© 2022 Gourav Roy. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;