import { createSelector } from "reselect"

export const projectSelector = (state) => {     
    return state.projects.projects.data
}

export const getMemoProjects = createSelector(
    [projectSelector],
    (projects) => {
        // console.log("project selector")
        return projects
    }
)



export const projectBySlugSelector = (state) => state.projects.projects