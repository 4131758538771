import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./routes/home/home.page";
import Portfolio from "./routes/portfolio/portfolio.page";
import ThemeSwitch from './components/theme-switch/theme-switch.component.jsx';
import NotFound from "./routes/404/not-found.component";


const App = () => {


    return (
        <div className="main-page">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="portfolio/*" element={<Portfolio />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            <ThemeSwitch />
        </div>
    );
}



export default App;
