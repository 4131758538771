import { combineReducers } from "redux";
import categoryReducer from "./categories/categories.reducer";
import projectReducer from "./projects/projects.reducer";
import serviceReducer from "./services/services.reducer";

const rootReducer = combineReducers({
    categories: categoryReducer,
    projects: projectReducer,
    services: serviceReducer,
})


export default rootReducer