import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMemoProjects } from "../../redux/projects/project.selector";
import ProjectCard from "../project-card/project-card.component";




const PortfolioCategoryList = (props) => {
    const { attributes} = props.params
    const [filteredProjects, setFilteredProjects] = useState([])

    const projects = useSelector(getMemoProjects)


    useEffect(() => {
        const filteredProject = [];

        if (projects) {
            const filterProject = projects.map((project) => {
                if (project.attributes.category.data !== null) {
                    if (project.attributes.category.data.attributes.Category === attributes.Category) {
                        filteredProject.push(project)
                    }
                }              
            })
            
            setFilteredProjects(filteredProject)
        }

    }, [attributes, projects])

    
    return (
        <div className="rn-portfolio-area rn-section-gap bg_color--5" id={attributes.Slug}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center mb--20">
                            <h2 className="title">{attributes.Category}</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        filteredProjects.length > 0 ? filteredProjects.map((project) => {
                          return <ProjectCard key={project.id} project={project} />
                        }) : ''
                            
                        
                    }
                    

                </div>
            </div>
        </div>
    )
}


export default PortfolioCategoryList