import React, { useContext, useEffect, useLayoutEffect, useState, useTransition } from "react"
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "../navigation/navigation.component";
import PortfolioBanner from "../../components/portfolio/portfolio-banner.component";
import Footer from "../navigation/footer.component";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectBySlug } from "../../redux/projects/projects.action";
import { projectBySlugSelector } from "../../redux/projects/project.selector";
import Modal from "../../components/portal/modal.component";
import $ from 'jquery';

const PortfolioPreview = () => {
    const { slug } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const projectBySlug = useSelector(projectBySlugSelector)

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(fetchProjectBySlug(slug))
        if (projectBySlug === 'ERR_BAD_REQUEST') {
            navigate('/404')
        }
    }, [])

    useEffect(() => {

        const onShow = () => {
            document.getElementById('popUpVideo').play();
            document.getElementById('popUpVideo').currentTime = 0;
        }

        window.addEventListener('shown.bs.modal', onShow, false)
        return () => window.removeEventListener('shown.bs.modal', onShow, false);

    }, [])


    useEffect(() => {

        const onHide = () => {
            document.getElementById('popUpVideo').pause();
        }

        window.addEventListener('hide.bs.modal', onHide, false)
        return () => window.removeEventListener('hide.bs.modal', onHide, false);

    }, [])


    return (

        <>
            <Navigation />
            <PortfolioBanner title={projectBySlug ? projectBySlug.Title : ''} param={projectBySlug ? projectBySlug.Title : ''} />

            {

                projectBySlug ? (
                    <main className="page-wrapper">
                        <div className="rn-portfolio-details ptb--120 bg_color--1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="portfolio-details">
                                            <div className="inner">
                                                <h2>{projectBySlug.Title}</h2>
                                                {/* <p className="subtitle">There are many variations of passages of Lorem Ipsum available,
                                    but the majority have suffered alteration in some form.</p> */}
                                                <p>{projectBySlug.Description}</p>
                                                <div className="portfolio-view-list d-flex flex-wrap">
                                                    <div className="port-view"><span>Client:</span>
                                                        <h6>{projectBySlug.Client}</h6>
                                                    </div>
                                                    <div className="port-view"><span>Project Types:</span>
                                                        <h6>{projectBySlug.category && projectBySlug.category.Category}</h6>
                                                    </div>
                                                    <div className="port-view"><span>Link</span>
                                                        <h6><a href={projectBySlug.Link} target="_blank">{projectBySlug.Link}</a></h6>
                                                    </div>
                                                    <br></br>
                                                    <div className="port-view"><span>Tech Stack:</span>
                                                        <div className="techs  d-flex">
                                                            {projectBySlug.tech_stacks && projectBySlug.tech_stacks.map((tech) => {
                                                                return <span key={tech.id} className="badge bg-secondary" style={{ marginRight: "5px", cursor: "default" }}>{tech.Name}</span>
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                    <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                        <li><a href="#"><i data-feather="facebook"></i></a></li>
                                        <li><a href="#"><i data-feather="linkedin"></i></a></li>
                                        <li><a href="#"><i data-feather="instagram"></i></a></li>
                                        <li><a href="#"><i data-feather="twitter"></i></a></li>
                                    </ul>
                                </div> */}
                                            </div>
                                            <br />
                                            <br />
                                            <br />
                                            <div className="portfolio-thumb-inner">
                                                {
                                                    projectBySlug.Images && projectBySlug.Images.map((image) => {
                                                        const filename = image.url;
                                                        const ext = filename.split('.').pop()
                                                        if (ext === 'mp4') {
                                                            return (
                                                                <div className="thumb position-relative mb--30" key={image.id} data-bs-toggle="modal" data-bs-target="#videoModal">
                                                                    <img src={process.env.REACT_APP_DATA_URL + projectBySlug.Images[2].url} alt={projectBySlug.Title} />
                                                                    <a className="video-popup position-top-center theme-color play__btn" style={{ cursor:"pointer" }}>
                                                                        <span className="play-icon"></span>
                                                                    </a>
                                                                    <Modal>
                                                                        <video id="popUpVideo" controls controlsList="nodownload" playsInline>
                                                                            <source src={process.env.REACT_APP_DATA_URL + filename} />
                                                                        </video>
                                                                    </Modal>
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <div className="thumb mb--30" key={image.id}>
                                                                    <img src={process.env.REACT_APP_DATA_URL + filename} alt={projectBySlug.Title} />
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                ) : ''
            }
            <Footer />
        </>
    )
}

export default PortfolioPreview;