import React, { useEffect } from "react"
import { useDispatch } from "react-redux";
import Contact from "../../components/contact/contact.component";
import About from "../../components/home-page/about.component";
import Banner from "../../components/home-page/banner.component";
import PortfolioComp from "../../components/home-page/portfolio.component";
import Services from "../../components/home-page/services.component";

import PageWrapper from "../../components/page-wrapper/page-wrapper.component";
import { fetchAllProjects } from "../../redux/projects/projects.action";
import { fetchAllServices } from "../../redux/services/services.action";
import Footer from "../navigation/footer.component";
import Navigation from "../navigation/navigation.component";

const Home = () => {    

    const dispatch = useDispatch()

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(fetchAllProjects())
        dispatch(fetchAllServices())
    }, [])

    return (
        <>
            <Navigation />
            <PageWrapper >
                <Banner />
                <About />
                <Services />
                <PortfolioComp/>
                <Contact />
                <Footer />
            </PageWrapper>
        </>
    )
}

export default Home;