import React from "react"
import ReactDOM  from "react-dom";


const Modal = ({children}) => {


    return ReactDOM.createPortal(
        <div className="modal fade scrollBar" id="videoModal" tabIndex="-1" aria-labelledby="videoModal" aria-hidden="true">
            <div className="modal-dialog  modal-xl modal-dialog-centered">
                <div className="modal-content">                  
                    <div className="modal-body">
                        {children}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="rn-button-style--2 btn_solid" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>,
        document.getElementById('portal')
    )
}


export default Modal;