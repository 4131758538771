import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMemoProjects } from "../../redux/projects/project.selector";
import ProjectCard from "../project-card/project-card.component";

const PortfolioComp = () => {
    const projects = useSelector(getMemoProjects)
    const [featured, setFeatured] = useState([])


    useEffect(() => {
        if (projects) {
            const featuredProject = projects.filter((project) => project.attributes.featured)
            setFeatured(featuredProject)
        }
    }, [projects])

    return (
        <div className="rn-portfolio-area rn-section-gap bg_color--1" id="portfolio" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title section-title--3 text-center mb--30">
                            <h2 className="title">My Latest Project</h2>
                            {/* <p>There are many variations of passages of Lorem Ipsum
                                available, <br /> but the
                                majority have suffered alteration.</p> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        featured && featured.map((project) => {
                            return <ProjectCard key={project.id} project={project} />
                        })
                    }
                    
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="view-more-btn mt--60 mt_sm--30 text-center">
                            <Link to="/portfolio" className="rn-button-style--2 btn_solid">View More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PortfolioComp;