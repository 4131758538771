import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Routes, Route } from "react-router-dom"


import { fetchCategories } from "../../redux/categories/categories.action"
import { getMemoCategories } from "../../redux/categories/categories.selector"
import PortfolioPreview from "../portfolio-preview/portfolio-preview.page"
import PortfolioIndex from "./portfolio-index"

const Portfolio = () => {

    const dispatch = useDispatch()    
    const categories = useSelector(getMemoCategories)


    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(fetchCategories())
    }, [])


    return (
        <Routes>
            <Route index element={<PortfolioIndex categories={categories} />} />
            <Route path=":slug" element={<PortfolioPreview />}/>
        </Routes>
    )
}


export default Portfolio;

