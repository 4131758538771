import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PortfolioBanner from "../../components/portfolio/portfolio-banner.component";
import PortfolioCategoryList from "../../components/portfolio/portfolio-category-list.component";
import PortfolioHeader from "../../components/portfolio/portfolio-header.component";
import { fetchAllProjects } from "../../redux/projects/projects.action";
import Footer from "../navigation/footer.component";

const PortfolioIndex = ({ categories }) => {  
    
    const dispatch = useDispatch()

    useEffect(() => {        
        dispatch(fetchAllProjects())
    })

    return (
        <>
            <PortfolioHeader categories={categories} />
            <PortfolioBanner title="Portfolio" params="" />
            <main className="page-wrapper">
                {
                    categories && categories.map((category) => {
                        if (category.attributes.projects.data.length) {
                            return <PortfolioCategoryList key={category.id} params={ category } />
                        }
                    })
                }
            </main>
            <Footer />
        </>
    )
}


export default PortfolioIndex;

