import axios from "axios"
import { FETCH_SERVICES_FAILURE, FETCH_SERVICES_REQUEST, FETCH_SERVICES_SUCCESS } from "./services.types"

export const fetchServicesRequest = () => {
    return {
        type: FETCH_SERVICES_REQUEST
    }
}


export const fetchServicesSuccess = (services) => {
    return {
        type: FETCH_SERVICES_SUCCESS,
        payload: services
    }
}


export const fetchServicesFailure = (error) => {
    return {
        type: FETCH_SERVICES_FAILURE,
        payload: error
    }
}



export const fetchAllServices = () => {
    return (dispatch) => {
        dispatch(fetchServicesRequest)

        axios.get(`${process.env.REACT_APP_API_URL}/service?populate=*`, {
            headers: {
                'Authorization': `bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        })
            .then((reponse) => {
                const services = reponse.data
                dispatch(fetchServicesSuccess(services))

            })
            .catch((error) => {
                const errMsg = error.message
                dispatch(fetchServicesFailure(errMsg))
            })
    }
}


