import { createSelector } from "reselect"

export const getServices = (state) => state.services.services.data


export const getMemoServices = createSelector(    
    [getServices],
    (services) => {
        // console.log("services selector")
       return services
    }
)