import React from "react";

const Banner = () => {
    return (
        <div className="rn-slider-area" id="home" >
            <div
                className="slide personal-portfolio-slider slider-paralax slider-style-3 dark-slider d-flex align-items-center justify-content-center bg_image bg_image--28">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner text-start">
                                {/* <span>Freelance digital designer</span> */}
                                <h1 className="title">Hi, I’m <span>Gaurav</span></h1>
                                <h2>Web Specialist.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner;