import React from "react";
import { HashLink as Link } from 'react-router-hash-link';


const PortfolioBanner = (props) => {
    return (
        <div className="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--5" data-black-overlay="6">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
                            <h2 className="title">{props.title}</h2>
                            <ul className="page-list">
                                <li><Link to="/">Home</Link></li>
                                <li className="current-page">
                                    {
                                        props.param ? (<Link to="/portfolio">Portfolio</Link>) : 'Portfolio'
                                    }
                                </li>
                                {
                                    props.param ? (
                                        <li className="current-page">{props.param}</li>
                                    ) : null
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PortfolioBanner;