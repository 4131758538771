import React from "react";


const About = () => {
    return (
        <div className="about-area rn-section-gap bg_color--1" id="about" >
            <div className="about-wrapper">
                <div className="container">
                    <div className="row row--35 align-items-center">
                        <div className="col-lg-5">
                            <div className="thumbnail">
                                <img className="w-100" src="/assets/images/about/about-8.jpg"
                                    alt="About Images" />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="about-inner inner pt--100 pt_sm--40 pt_md--40">
                                <div className="section-title">
                                    <h2 className="title">About Me</h2>
                                    <p className="description">I’m a self-evolved design and web enthusiast. My skillsets include designing posters, promotional banners, graphic design, presentations, website development and exploring in video and animations.
                                    </p>
                                </div>
                                <div className="tab-wrapper mt--30">
                                    <ul className="nav nav-tabs tab-style--1" id="myTab"
                                        role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active"
                                                id="mainskill"
                                                data-bs-toggle="tab"
                                                data-bs-target="#rn-mainskill"
                                                role="tab"
                                                aria-controls="mainskill"
                                                aria-selected="true">Main
                                                skills</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="awards"
                                                data-bs-toggle="tab"
                                                data-bs-target="#rn-awards"
                                                role="tab"
                                                aria-controls="awards"
                                                aria-selected="false">Awards</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="experience"
                                                data-bs-toggle="tab"
                                                data-bs-target="#rn-experience"
                                                role="tab"
                                                aria-controls="experience"
                                                aria-selected="false">Experience</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="education"
                                                data-bs-toggle="tab"
                                                data-bs-target="#rn-education"
                                                role="tab"
                                                aria-controls="education"
                                                aria-selected="false">Education
                                                & Certification</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active"
                                            id="rn-mainskill" role="tabpanel"
                                            aria-labelledby="mainskill">
                                            <div className="single-tab-content">
                                                <ul>
                                                    <li><a href="service.html">User
                                                        experience
                                                        design
                                                        <span> -
                                                            UI/UX</span></a>Delight
                                                        the user and
                                                        make it
                                                        work.</li>
                                                    <li><a href="service.html">Full-stack web development<span>
                                                            -
                                                            Development</span></a>Websites,
                                                        web
                                                        experiences,
                                                        ...</li>
                                                    <li><a href="service.html">Backend development & deployment<span>
                                                        -
                                                        Development</span></a>Websites,
                                                    web
                                                    experiences,
                                                    ...</li>
                                                    <li><a href="service.html">Video editing & Motion graphics
                                                        <span> -
                                                            Animation</span></a>I
                                                        like to move it
                                                        move it.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="rn-awards"
                                            role="tabpanel"
                                            aria-labelledby="awards">
                                            <div className="single-tab-content">
                                                <ul>
                                                    <li><a href="service.html">Cryptic award
                                                        <span>-
                                                            May 2019</span></a>
                                                        For exceptional work in web development
                                                    </li>
                                                    <li><a href="service.html">Cryptic award
                                                        <span>-
                                                            December 2018</span></a>
                                                        For exceptional work in web development</li>
                                                    <li><a href="service.html">Metamorphix Portfolio Review Award
                                                        <span>-
                                                            November 2018</span></a>
                                                        For IoT & Augmented Reality based Automated Irrigation System</li>
                                                    <li><a href="service.html">Cryptic award
                                                        <span>-
                                                            June 2018</span></a>
                                                            For exceptional work in web development</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade"
                                            id="rn-experience" role="tabpanel"
                                            aria-labelledby="experience">
                                            <div className="single-tab-content">
                                                <ul>
                                                    <li><a href="/service">Web Specialist<span>
                                                             -
                                                            RR Donnelley</span></a>
                                                        Sep 2019
                                                        - Current</li>
                                                    <li><a href="/service">Sr. Web Developer<span>
                                                             -
                                                            RR Donnelley</span></a>
                                                        Jul 2018
                                                        - Sep 2019</li>
                                                    <li><a href="/service">Sr. Web Developer (Contract)<span>
                                                             -
                                                            Gem Software Sol.
                                                        </span></a>
                                                        Jan 2018 -
                                                        Jul 2018</li>
                                                    <li><a href="/service">Web Developer (Freelance)<span>
                                                         -
                                                        Nutshell Ideas
                                                    </span></a>
                                                    Jun 2015 -
                                                    May 2017</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade"
                                            id="rn-education" role="tabpanel"
                                            aria-labelledby="education">
                                            <div className="single-tab-content">
                                                <ul>
                                                    <li><a href="/service">B.Com<span>
                                                            -
                                                            Yashwantrao Chavan Maharashtra Open University</span></a>
                                                        2022</li>
                                                    <li><a href="/service">HSE - Commerce<span>
                                                            -
                                                            DAV Public School, Korba</span></a>
                                                        2009</li>
                                                    <li><a href="/service">Certified in Technical Support Fundamentals<span>
                                                            -
                                                            Authorized by Google and offered through Coursera</span></a>
                                                        2021</li>
                                                    <li><a href="/service">Participation certificate of Kaizen IoT workshop<span>
                                                        -
                                                        Conducted by Lema Labs, Chennai</span></a>
                                                    2018</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;