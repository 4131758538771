import React from 'react';

const ThemeSwitch = () => {
    return (
		<>
			<div id="my_switcher" className="my_switcher">
                        <ul>
                              <li style={{ cursor:"pointer" }}>
                                    <a data-theme="light" className="setColor light">
                                          <img src="/assets/images/about/sun-01.svg" alt="Sun images" /><span title="Light Mode">
                                                Light</span>
                                    </a>
                              </li>
                              <li style={{ cursor:"pointer" }}>
                                    <a data-theme="dark" className="setColor dark">
                                          <img src="/assets/images/about/vector.svg" alt="Vector Images" /><span
                                                title="Dark Mode"> Dark</span>
                                    </a>
                              </li>
                        </ul>
                  </div>
		</>
    );
}

export default ThemeSwitch  ;