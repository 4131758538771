import axios from "axios";
import React, { Fragment, useEffect, useState } from "react"
import { Outlet } from "react-router-dom";
import  { HashLink as Link } from 'react-router-hash-link';

const Navigation = () => {

    const [resume, setResume] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/resumes?populate=Resume`, {
            headers: {
                'Authorization': `bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        })
            .then((reponse) => {
                const resume = reponse.data.data[0].attributes.Resume.data.attributes.url
                setResume(resume)
            })
            .catch((error) => {
                console.log(error.message)
            })
    }, []);    

	return (
		<Fragment>
			<header className="header-area header-style-two header--transparent header-light-mode header--sticky" >
				<div className="header-wrapper">
					<div className="header-left d-flex align-items-center">
						<div className="logo">
							<Link to="/">
								<img src={process.env.PUBLIC_URL + '/assets/images/logo_white.svg'} className="logo-img" alt="Gourav Roy" />
							</Link>
						</div>
						<nav className="mainmenunav d-lg-block ml--50">
							<div className="navbar-example2">
								{/*  Start Mainmanu Nav  */}
								<ul className="mainmenu nav nav-pills onepagenav">
									<li className="nav-item current">
										<Link className="nav-link" to="/#home" smooth>Home</Link>
									</li>
									<li>
										<Link className="nav-link" to="/#about" smooth>About</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" to="/#service" smooth>Service</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" to="/#portfolio" smooth>Portfolio</Link>
									</li>								
									<li className="nav-item">
										<Link className="nav-link" to="/#contact" smooth>Contact</Link>
									</li>
								</ul>
								{/*  End Mainmanu Nav  */}
							</div>
						</nav>
					</div>
					<div className="header-right">
						<div className="social-share-inner">
							<ul
								className="social-share social-style--2 d-flex justify-content-start liststyle">
								<li><a target="_blank" href="https://www.facebook.com/grvroy9"><i className="fab fa-facebook-f"></i></a></li>
								<li><a target="_blank" href="https://www.linkedin.com/in/grvroy90/"><i className="fab fa-linkedin-in"></i></a></li>
								<li><a target="_blank" href="https://twitter.com/grvroy90"><i className="fab fa-twitter"></i></a></li>
								<li><a target="_blank" href="https://www.instagram.com/gourav_r_official/"><i className="fab fa-instagram"></i></a></li>
							</ul>
						</div>
                        <div className="header-btn ml_md--20 ml_sm--0">
                            { 
                                resume && <a className="rn-btn" href={process.env.REACT_APP_DATA_URL + resume} download target="_blank"><span>Resume</span></a>
                             }
						</div>
						{/*  Start Humberger Menu   */}
						<div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
							<span className="menutrigger text-white">
								<i data-feather="menu"></i>
							</span>
						</div>
						{/*  End Humberger Menu   */}
						{/*  Start Close Menu   */}
						<div className="close-menu d-block d-lg-none">
							<span className="closeTrigger">
								<i data-feather="x"></i>
							</span>
						</div>
						{/*  End Close Menu   */}
					</div>
				</div>
			</header>
		</Fragment>
	)
}

export default Navigation;
