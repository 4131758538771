import { createSelector } from "reselect"

export const categoriesSelector = (state) => {
    return state.categories.categories.data
}


export const getMemoCategories = createSelector(    
    [categoriesSelector],
    (categories) => {
        // console.log("categories selector")
       return categories
    }
)