import axios from "axios"
import { FETCH_PROJECTS_FAILURE, FETCH_PROJECTS_REQUEST, FETCH_PROJECTS_SUCCESS } from "./projects.types"

export const fetchProjectsRequest = () => {
    return {
        type: FETCH_PROJECTS_REQUEST
    }
}


export const fetchProjectsSuccess = (projects) => {
    return {
        type: FETCH_PROJECTS_SUCCESS,
        payload: projects
    }
}


export const fetchProjectsFailure = (error) => {
    return {
        type: FETCH_PROJECTS_FAILURE,
        payload: error
    }
}



export const fetchAllProjects = () => {
    return (dispatch) => {
        dispatch(fetchProjectsRequest)

        axios.get(`${process.env.REACT_APP_API_URL}/projects?populate=*`, {
            headers: {
                'Authorization': `bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        })
            .then((reponse) => {
                const projects = reponse.data
                dispatch(fetchProjectsSuccess(projects))

            })
            .catch((error) => {
                const errMsg = error.message
                dispatch(fetchProjectsFailure(errMsg))
            })
    }
}




export const fetchProjectBySlug = (slug) => {
    return (dispatch) => {
        dispatch(fetchProjectsRequest)

        axios.get(`${process.env.REACT_APP_API_URL}/projects/${slug}?populate=*`, {
            headers: {
                'Authorization': `bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        })
            .then((reponse) => {
                const projects = reponse.data
                dispatch(fetchProjectsSuccess(projects))

            })
            .catch((error) => {
                const errMsg = error.message
                dispatch(fetchProjectsFailure(errMsg))
            })
    }
}